<template>
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Events
				</h2>
			</div>
			<!-- <div class="mt-4 flex md:mt-0 md:ml-4">
				<RefreshTableButton @refreshTable="refreshTable" />
				<router-link
					:to="{ name: 'content-events-create' }"
					class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
				>
					<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Add Event
				</router-link>
			</div> -->
		</div>

		<div class="mb-8">
			<div class="block flex border-b border-gray-200">
				<div class="flex-1">
					<nav class="-mb-px flex space-x-8" aria-label="Tabs">
						<button
							:class="[
								tab == 'event-templates'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="tab == 'event-templates' ? 'page' : undefined"
							@click.prevent="tab = 'event-templates'"
						>
							Event Templates
						</button>
						<button
							:class="[
								tab == 'events'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="tab == 'events' ? 'page' : undefined"
							@click.prevent="tab = 'events'"
						>
							Events
						</button>
						<button
							:class="[
								tab == 'schedule-events'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="tab == 'schedule-events' ? 'page' : undefined"
							@click.prevent="tab = 'schedule-events'"
						>
							Schedule Events
						</button>
					</nav>
				</div>

				<div v-if="tab == 'event-templates'" class="mt-3 flex h-10 sm:mt-0 sm:ml-4">
					<RefreshTableButton @refreshTable="refreshTable" />

					<router-link
						:to="{ name: 'content-events-create' }"
						class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
					>
						<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Event Template
					</router-link>
				</div>

				<div v-if="tab == 'events'" class="mt-3 flex h-10 sm:mt-0 sm:ml-4">
					<RefreshTableButton @refreshTable="refreshTableEvents" />

					<button
						class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="addEventInstance"
					>
						<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Event
					</button>
				</div>

				<div v-if="tab == 'schedule-events'" class="mt-3 flex h-10 sm:mt-0 sm:ml-4">
					<RefreshTableButton @refreshTable="$refs.eventsCalendar.refreshCalendar()" />

					<!-- <button
						class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="scrollTo('document')"
					>
						<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Add Document
					</button> -->
				</div>
			</div>
		</div>

		<div v-if="tab == 'event-templates'" class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<ag-grid-vue
						style="height: 725px"
						class="ag-theme-alpine"
						:grid-options="eventTemplatesGridOptions"
						:column-defs="eventTemplatesColumnDefs"
						:default-col-def="eventTemplatesDefaultColDef"
						:row-data="eventTemplatesRowData"
						:modules="eventTemplatesModules"
						:pagination="true"
						:pagination-page-size="50"
						:enable-cell-text-selection="true"
						:ensure-dom-order="true"
					></ag-grid-vue>
				</div>
			</div>
		</div>

		<div v-if="tab == 'events'" class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<ag-grid-vue
						style="height: 725px"
						class="ag-theme-alpine"
						:grid-options="eventsGridOptions"
						:column-defs="eventsColumnDefs"
						:default-col-def="eventsDefaultColDef"
						:row-data="eventsRowData"
						:modules="eventsModules"
						:pagination="true"
						:pagination-page-size="50"
						:enable-cell-text-selection="true"
						:ensure-dom-order="true"
					></ag-grid-vue>
				</div>
			</div>

			<EventsModalsEventInstance
				v-if="eventInstanceOpen"
				ref="eventInstance"
				:open="eventInstanceOpen"
				:event-instance="eventInstance"
				:event-instance-date="eventInstanceDate"
				@closeModal="closeModal"
				@eventInstanceCreated="eventInstanceCreated"
				@eventInstanceUpdated="eventInstanceUpdated"
			/>
		</div>

		<div v-if="tab == 'schedule-events'" class="mt-8 flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<EventsCalendar ref="eventsCalendar" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActions from '@/components/Events/TableActions.vue'
import TableActionsById from '@/components/Events/TableActionsById.vue'

import { PlusIcon } from '@heroicons/vue/solid'

export default {
	name: 'Events',
	components: {
		AgGridVue,
		PlusIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			tab: 'event-templates',
			eventTemplatesColumnDefs: null,
			eventTemplatesRowData: null,
			eventTemplatesDefaultColDef: null,

			eventTemplatesModules: [ClientSideRowModelModule],

			eventsColumnDefs: null,
			eventsRowData: null,
			eventsDefaultColDef: null,

			eventsModules: [ClientSideRowModelModule],

			eventInstance: {},
			eventInstanceOpen: false,
			eventInstanceDate: null,
		}
	},
	watch: {
		tab: function(val) {
			// console.log('tab', this.tab)
			if (val === 'event-templates') {
				this.fetchEvents()
			} else if (val === 'events') {
				this.fetchEventInstances()
			}
		},
	},
	beforeMount() {
		this.eventTemplatesGridOptions = {
			context: {
				componentParent: this,
			},
		}
		this.eventsGridOptions = {
			context: {
				componentParent: this,
			},
		}

		this.eventTemplatesColumnDefs = [
			{ headerName: 'Name', field: 'title', flex: 1 },
			{
				headerName: 'Registration Enabled',
				field: 'registration_enabled',
				width: 200,
				valueFormatter: this.booleanFormatter,
				cellRenderer: params => {
					return params.value
						? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Yes</span>'
						: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">No</span>'
				},
			},
			{ headerName: 'Lifetime Registrations', field: 'registration_count' },
			{ headerName: 'Future Events Count', field: 'future_events_count' },
			{ headerName: 'Created At', field: 'created_at' },
			{ headerName: 'Last Updated', field: 'updated_at' },
			{
				headerName: 'Actions',
				width: 200,
				cellRendererFramework: TableActions,
				cellRendererParams: {
					editRoute: 'content-events-id',
					showEdit: true,
					newTab: true,
					showDelete: true,
					deleteApi: 'events',
					deleteTitle: 'Delete Event Template',
					deleteContent: 'Are you sure you want to delete this event template?',
					deleteRoute: 'content-events',
					deleteCheck: true,
					appApi: this.api,
				},
			},
		]
		this.eventsColumnDefs = [
			{ headerName: 'Name', field: 'title', flex: 1 },
			{ headerName: 'Venue / Virtual Link', field: 'venue', flex: 1 },
			{ headerName: 'Event Coordinator', field: 'user.name', width: 170 },
			{ headerName: 'Registration Opens', field: 'opens', width: 170 },
			{ headerName: 'Registration Cutoff', field: 'cutoff', width: 170 },
			{ headerName: 'Event Starts', field: 'starts', width: 170 },
			{ headerName: 'Event Ends', field: 'ends', width: 170 },
			{
				headerName: 'Actions',
				width: 250,
				cellRendererFramework: TableActionsById,
				cellRendererParams: {
					showEdit: true,
					showDelete: true,
					deleteTitle: 'Delete Event',
					deleteContent: 'Are you sure you want to delete this event?',
					deleteRoute: 'content-events',
					appApi: this.api,
					parent: this,
				},
			},
		]

		this.eventTemplatesDefaultColDef = {
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		}
		this.eventsDefaultColDef = {
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		}
	},
	mounted() {
		this.fetchEvents()
	},
	methods: {
		fetchEvents(reload = false) {
			this.loaderShow()

			this.api.events
				.all()
				.then(data => {
					this.eventTemplatesRowData = data
				})
				.catch(error => {
					this.toast.error('Error fetching event templates:\n' + error)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		fetchEventInstances(reload = false) {
			this.loaderShow()

			this.api.events.eventInstances
				.all()
				.then(data => {
					this.eventsRowData = data
				})
				.catch(error => {
					this.toast.error('Error fetching events:\n' + error)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		addEventInstance() {
			this.eventInstanceOpen = true
		},

		editEvent(event) {
			this.eventInstance = event
			console.log('eventInstance', this.eventInstance)
			this.eventInstanceOpen = true
		},

		eventInstanceCreated(eventInstances) {
			this.closeModal()

			this.fetchEventInstances()
		},

		eventInstanceUpdated(eventInstances) {
			this.closeModal()

			this.fetchEventInstances()
		},

		closeModal() {
			this.eventInstanceOpen = false
			this.eventInstance = {}
		},

		refreshTable() {
			this.fetchEvents(true)
		},
		refreshTableEvents() {
			this.fetchEventInstances(true)
		},

		booleanFormatter(params) {
			return params.value == '0' ? 'No' : 'Yes'
		},
	},
}
</script>
